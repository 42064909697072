<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
              Domain Ownership Verification
              <b-badge variant="primary">{{ domain_name }}</b-badge>
            </h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <b-card no-body class="mb-1" v-if="!domain.domain_hosting_only">
            <b-card-header header-tag="header" class="p-3" role="tab" v-b-toggle.accordion-2>
              <span class="checkout-step-number">1</span> Verify your domain with a TXT Record
            </b-card-header>
            <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <b-card-text>
                  <p>A TXT record (short for text record) is a type of resource record in the Domain Name System (DNS)
                    used to provide the ability to associate arbitrary text with a host or other name, such as human
                    readable information about a server, network, data center, or other accounting information.</p>
                  <h4>To verify your domain, create a TXT record on your domain DNS with following record.</h4>
                  <div class="row">
                    <div class="co-md-12 col-sm-12 col-lg-12">
                      <label class="control-label" for="name">Name / Host / Alias</label>
                      <p class="form-control">{{ txt_record.host }}</p>
                    </div>
                    <div class="co-md-12 col-sm-12 col-lg-12">
                      <label class="control-label" for="name">Value / Destination</label>
                      <p class="form-control">{{ txt_record.destination }}</p>
                    </div>
                    <div class="co-md-12 col-sm-12 col-lg-12">
                      <b-button  variant="success" class="text-white" @click.prevent="verifyDns">Verify Text Record</b-button>
                      <p v-if="txtMsg" :class="`${errorStatus ? 'text-danger' : 'text-success'} mt-2`">{{ txtMsg }}</p>
                    </div>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-3" role="tab" v-b-toggle.accordion>
              <span class="checkout-step-number">
                {{ domain.domain_hosting_only ? "1" : "2" }}
              </span> Update your domain nameserver (NS) Record
            </b-card-header>
            <b-collapse
                id="accordion"
                :visible="domain.verification_method=='nameserver'"
                accordion="my-accordion"
                role="tabpanel"
            >
              <b-card-body>
                <b-card-text>
                  <p>
                    <strong>What are nameservers?</strong>
                    <br/>Nameservers act like signposts on the Internet, showing Internet Service Providers (ISP's)
                    where to find information on your domain name.
                    When someone visits your website,
                    or sends you an email, their ISP checks your domain nameservers to find out where your website and
                    any other services are hosted.
                    Each domain name has at least two nameservers so that if one should fail, or is busy, the other
                    nameserver can take over and host your site. To change your hosting provider, you need to change
                    your domain name's nameserver records.
                  </p>
                  <h4>To verify &amp; manage your domain with Yelko, update your domain NS records to following.</h4>
                  <div class="row">
                    <div class="co-md-12 col-sm-12 col-lg-12 p-l-10 bg-light  mt-4">
                      <h5>{{ nameserver.primary_server }}</h5>
                      <h5>{{ nameserver.secondary_server }}</h5>
                      <h5>{{ nameserver.tertiary_server }}</h5>
                    </div>

                    <div class="co-md-12 col-sm-12 col-lg-12">
                      <br/>
                      <p>It can take up to 72 hours for any changes to your nameservers to take effect.</p>
                    </div>
                    <div class="co-md-12 col-sm-12 col-lg-12">
                      <b-button
                          variant="success"
                          class="text-white"
                          @click.prevent="verifyNameServer"
                      >Verify Nameserver Record
                      </b-button>
                      <p v-if="txtMsg" :class="`${errorStatus ? 'text-danger' : 'text-success'} mt-2`">{{ txtMsg }}</p>
                    </div>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DomainVerificationService from "@/services/domain/DomainVerificationService";
import WebsiteService from "@/services/Websites/WebsiteService";

const websiteService = new WebsiteService();
const verificationService = new DomainVerificationService();

export default {
  data() {
    return {
      app_url: null,
      domain: {},
      txtMsg: null,
      errorStatus :false,
      website_exist: null,
      txt_record: {
        host: null,
        destination: null
      },
      nameserver: {
        primary_server: "ns1.yelko.com",
        secondary_server: "ns2.yelko.com",
        tertiary_server: "ns3.yelko.com"
      }
    };
  },
  computed:{
    domain_name(){
      return this.$route.params.domain_name
    }
  },
  mounted() {
    this.app_url = process.env.VUE_APP_URL;
    this.getByDomainName();
    this.checkDomainExist();
  },

  methods: {
    checkDomainExist() {
      websiteService.domainExist(this.domain_name).then(res => {
        this.website_exist = res.data;
      });
    },
    getByDomainName() {
      verificationService
          .show(this.domain_name)
          .then(response => {
            this.domain = response.data.domain;
            this.txt_record.host = "@";
            this.txt_record.destination = response.data.domain.txt_value;
          })
          .catch(error => {
          });
    },

    verifyDns() {
      verificationService
          .verifyDns(this.domain_name, "create")
          .then(response => {
            if (!response.data.status) {
              this.txtMsg = response.data.msg;
              this.errorStatus = true;
            } else {
              this.errorStatus = false;
              this.txtMsg = "TXT record has been verfified";
              this.$snotify.success("TXT record has been created.");
              if (this.website_exist) {
                this.$router.push({
                  name: "manage-websites",
                  params: {domainname: domainName}
                });
              } else {
                this.createWebsite(this.domain_name);
              }
            }
          })
          .catch(error => {
          });
    },

    createWebsite(domainName) {
      let website = {
        domain_name: domainName,
        sub_domain_name: domainName.split('.')[0] + "." + this.app_url,
        is_custom_domain: 1,
        is_active: 1
      };
      setTimeout(() => {
        websiteService
            .create(website)
            .then(res => {
              this.$router.push({
                name: "website-subscription-plan",
                params: {domainname: domainName}
              });
            })
            .catch(err => {
            });
      }, 2000);
    },

    verifyNameServer() {
      verificationService
          .verifyNameServer(this.domain_name, "create")
          .then(response => {
            if (!response.data.status) {
              this.txtMsg = response.data.msg;
            } else {
              this.txtMsg =
                  "Nameserver record has been verified. Please wait while we setup your website...";
              this.$snotify.success("Nameserver record has been created.");
              if (this.website_exist) {
                this.$router.push(
                    {name: 'manage-websites', params: {domainname: this.domain_name}}
                );
              } else {
                this.createWebsite(this.domain_name);
              }
            }
          })
          .catch(error => {
          });
    }
  }
};
</script>

<style>
.checkout-step-number {
  border-radius: 50%;
  border: 1px solid #666;
  display: inline-block;
  font-size: 12px;
  height: 32px;
  margin-right: 26px;
  padding: 6px;
  text-align: center;
  width: 32px;
}
</style>

